import { EnumBlockCodeTracking } from '../constants/tracking';

export const PRODUCT_TYPE = {
  NORMAL: 'Normal',
  DEAL: 'Deal',
  COMBO: 'Combo',
  SKU_CONTRACT: 'Sku_contract',
  CAMPAIGN: 'Campaign',
} as const;

export const PRODUCT_ERROR = {
  OUT_OF_STOCK: 'Out_of_stock',
  CONSUMED_MAX_QUANTITY: 'Consumed_max_quantity',
  SUSPENDED: 'Suspended',
  SUSPENDED_SKU: 'SUSPENDED_SKU',
  STOP_PRODUCING: 'Stop_producing',
  NOT_SUPPORT_DELIVERY: 'Not_support_delivery',
  MAX_QUANTITY: 'Max_quantity',
  OUT_OF_STOCK_SKU: 'Out_of_stock_sku',
} as const;

export const PRODUCT_CODE = {
  HOADONNHANH: 'Hóa Đơn Nhanh',
  DOCQUYENGIATOT: 'Độc Quyền Giá Tốt',
  NEAR_EXPIRATION: 'Cận Date',
  PROMOTION: 'Khuyến Mãi',
  LIMIT: 'Số Lượng Có Hạn',
  FLASHSALE: 'Flash Sale',
  DEAL: 'DEAL',
  HANG_DAT_TRUOC: 'Hàng Đặt Trước',
  '2ABC': 'Hàng Hãng',
  Y7GB: 'Hàng Điểm',
  GY7G: 'Nhân Đôi Tích Lũy',
  '3ABC': 'Bán Chạy',
} as const;

export const PRODUCT_LABEL = {
  V2U1: 'Mới',
  '2ABC': 'Hàng Hãng',
} as const;

export const FLUCTUATED_TYPE = {
  INCREASED: 'INCREASED',
  DECREASED: 'DECREASED',
} as const;

export enum EnumHighProfitLayer {
  'DOUBLE_LAYER' = 2,
  'TRIBLE_LAYER' = 3,
}

export type ProductType = keyof typeof PRODUCT_TYPE;
export type ProductError = keyof typeof PRODUCT_ERROR;
export type ProductTags = keyof typeof PRODUCT_CODE;
export type ProductLabel = keyof typeof PRODUCT_LABEL;
export type ProductFluctuated = keyof typeof FLUCTUATED_TYPE;

export enum EnumVoucherSuggestVoucherType {
  CartVoucherList = 'CartVoucherList',
  SellerVoucherList = 'SellerVoucherList',
}

export interface Tag {
  code: ProductTags;
  iconUrl: string;
  url: string;
  name: string;
  backgroundColor: string;
  textColor: string;
}

export interface Label {
  code: ProductLabel;
  iconUrl: string;
  name: string;
  url: string;
}

export interface DiscountDetail {
  message: string;
  totalDiscount: number;
  voucherDetails: {
    discountValue: number;
    isApply: boolean;
    voucherCode: string;
  }[];
}

export interface ProductReplace {
  imageUrl: string;
  priceEncrypted: string;
  productId: number;
  productName: string;
  slug: string;
  volume: string;
  price: number;
  sellerId: number;
  skuCode: string;
  errorCode: string;
  isHighProfit?: boolean;
  styleDisplay?: {
    web: TPlatFormStylDisplay;
  }
}

export interface ProductQuickOrder {
  id: string;
  name: string;
  imageUrl: string;
  tags?: Tag[];
  priceDiscount?: number;
  discountPercent?: number;
  currentPrice: number;
  quantityInCart?: number;
  maxQuantity: number;
  limitMessage: string;
  slug: string;
  sellerId: number;
  sellerName: string;
  sellerUrl: string;
  sellerCode: string;
  skuCode: string;
  skuReplace?: ProductReplace[];
  isWishlist: boolean;
  isDeal: boolean;
  labels: Label[];
  errorCode?: ProductError;
  errorMessage?: string;
  warningMessage?: string;
  type: ProductType;
  productTags: string[];
  blockCode?: EnumBlockCodeTracking;
  // high profit product
  isHighProfit?: boolean;
  styleDisplay?: {
    web: TPlatFormStylDisplay;
  };
}

export type ProductCart = Pick<
  ProductQuickOrder,
  'skuCode' | 'sellerName' | 'sellerCode' | 'sellerId' | 'quantityInCart' | 'maxQuantity' | 'name' | 'id' | 'type'
>;

// CART
export interface Product {
  productId?: number;
  last_quantity?: number;
  current_quantity?: number;
  quantity?: number;
  id: number;
  imageUrl: string;
  name: string;
  volume: string;
  tags: Tag[];
  price: number;
  discountPrice: number;
  discountDetail?: DiscountDetail;
  oldPrice: number;
  discountPercent: number;
  fluctuatedType?: ProductFluctuated;
  priceLabel?: string;
  skuCode: string;
  type: string;
  isFluctuated: boolean;
  isImportant: boolean;
  isSelected: boolean;
  slug: string;
  labels: Label[];
  nearExpireMessage?: string;
  limitMessage: string;
  errorCode?: ProductError;
  errorMessage?: string;
  warningCode?: string;
  warningMessage?: string;
  maxQuantity: number;
  quantityInCart?: number;
  sellerId: string;
  sellerCode: string;
  sellerGroup?: string;
  isCanClickable: boolean;
  productCode: string;
  paymentMethod: string;
  redeemApplyResult: IRedeemApplyResult[];
  storeCode: string;
  productTags: string[];
  noneVoucherPrice: number;
  retailPriceApplyVoucher: number;
  skuReplace?: ProductReplace[];
  eventSource?: string;
  recommendSKUs?: string;
  metric?: string;
  blockCode?: EnumBlockCodeTracking;
  content?: string;
  // high profit product
  isHighProfit?: boolean;
  styleDisplay?: {
    web: TPlatFormStylDisplay;
  };
}

export interface Seller {
  code: string;
  products: Product[];
  imageUrl: string;
  sellerGroup: string;
  name: string;
  isSelected: boolean;
  slug: string;
  totalDiscount: number;
  storeCode: string;
}

export interface CartDiscount {
  autoApply?: boolean;
  canUse: boolean;
  code: string;
  discountValue: number;
  message?: string;
  gifts?: IGifts[];
}

export interface CartStore {
  cartNo: string;
  cartData: Seller[];
  cartDiscounts: CartDiscount[];
  cartFluctuates: Product[];
  cartErrors: Product[];
  selectedProducts: Product[];
  isOpenImportantProducts: boolean;
  isSelected: boolean;
  totalDiscount: number;
  totalItems: number;
  totalItemsSelected: number;
  totalPrice: number;
  totalQuantity: number;
  totalQuantitySelected: number;
  totalItemsImportantSelected: number;
  price: number;
  subPrice: number;
  hasDeal: boolean;
  selectedLists: {
    [key in string]: string[];
  };
  isFirstTimeGetCart: boolean;
  redeemApplyResult: IRedeemApplyResult[];
  paymentMethod: string;
  redeemCode: string[];
  oldCartItems: IOldCartItem[];
  promosRemoved: string;
  nextUsingPromo: {
    code: string;
    paymentMethod: string;
    paymentMethodName: string;
  } | null;
  lastUpdatedIDRef?: string;
  triggerOpenAndScroll: number;
  voucherSuggestions: {
    [key in string]: IVoucherSuggestions;
  };
}

export interface ProductCartStore {
  sellerClass: string;
  sellerCode: string;
  sellerId: number;
  sellerType: string;
  sellerUrl: string;
  isSelected: boolean;
  items: Product[];
}

export interface IRedeemApplyResult {
  autoApply: boolean;
  canUse: boolean;
  code: string;
  discountValue: number;
  gifts: IGifts | null;
  message: string;
  paymentMethod: string;
}

export interface IGifts {
  quantity: number;
  sku: string;
}

export interface IOldCartItem {
  quantity: number;
  price: number;
  total: number;
  sku: string;
  sellerCode: string;
  productTags: string[];
  productCode: string;
  isSelected: boolean;
  storeCode: string;
}

export const SkuType = {
  NORMAL: 'NORMAL',
  COMBO: 'COMBO',
  DEAL: 'DEAL',
  SKU_CONTRACT: 'SKU_CONTRACT',
} as const;

export const SellerType = {
  INDIVIDUAL: 'INDIVIDUAL',
  ENTERPRISE: 'ENTERPRISE',
  MARKET: 'MARKET',

  TRADING: 'TRADING',
  NON_TRADING: 'NON-TRADING',
} as const;

export const SellerClass = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  VENDOR: 'VENDOR',
} as const;

export type TagLabelQuickOrder = {
  name: string;
  iconUrl: string;
  url: string | { pathname: string };
  tooltip: string;
  code: string;
  relativeCode: string;

  textColor: string;
  backgroundColor: string;
};

export type ProductDataLite = {
  productName: string;
  productId: number;
  skuCode: string;
  slug: string;
  imageUrl: string;
  type: keyof typeof SkuType;
  discountPriceEncrypted: string;
  priceEncrypted: string;
  discountPercent: number;
  tags: TagLabelQuickOrder[];
  labels: TagLabelQuickOrder[];
  maxQuantity: number;
  quantityInCart: number;
  retailPriceApplyVoucherEncrypt: string;

  sellerName: string;
  sellerId: number;
  sellerCode: string;
  sellerUrl: string;

  sellerSlug: string;
  sellerAvatar: string;
  sellerType: keyof typeof SellerType;
  sellerClass: keyof typeof SellerClass;
  isSellerStore: boolean; // case vendor store
  isSellerVip: boolean;
  isVendorStore: boolean;
  unit: string;
  // sellerStatus: SellerStatus  string ,

  isShadowSKU: boolean;
  isWishlist: boolean;

  // hasGift: HasGift boolean ,
  hasContract: boolean;

  warningMessage: string;
  limitMessage: string;
  errorMessage: string;
  errorCode: string;

  skuReplace: ProductDataLite[];

  // for tracking
  isSKUReplace: boolean;
  volume: string;
  eventSource: string;
  // high profit
  isHighProfit?: boolean;
  styleDisplay?: {
    web: TPlatFormStylDisplay;
  };
};

export type ProductDataLiteVertical = ProductDataLite & {
  campaignPriceLabel: string;
  dealText: string;
  dealSoldPercent: number;
  dealEndTime: string;
  nearExpirationLabel: string;
  skuReplace: ProductDataLiteVertical[];
  salePrice: number;
  dataParent?: {
    productId: string;
    sellerId: number;
    skuCode: string;
    errorCode: string;
  };
};
export interface IVoucherSuggestions {
  inUse?: boolean;
  label: string;
  param: {
    percent: string;
    maxDiscount: string;
    discount: string;
    paymentMethod: string;
    errorMessage: string;
    shortName: string;
    displayName: string;
    voucherCode: string;
  };
  owner: string;
  sellerCode: string;
  storeCode?: string;
  voucherType: EnumVoucherSuggestVoucherType;
}

export type TPlatFormStylDisplay = {
  background: {
    colors: string[];
    percentsColor: number[];
    angle: number;
  };
};
